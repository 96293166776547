import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import logo from '../../assets/prodocs-logo.png';
import Select, { components } from "react-select";
import makeAnimated from 'react-select/animated';
import searchImg from '../../assets/search.svg';
import trash from '../../assets/trash.svg';
import Footer from "../Footer/Footer";
import CreateTemplateNavbar from "../Navbar/CreateTemplateNavbar";
import AddPlus from '../../assets/add_tax.svg'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Table, Button, Col, Modal} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RemoveIcon from '@material-ui/icons/Remove';
import ToggleIcon from "material-ui-toggle-icon";
import IconButton from "@material-ui/core/IconButton";
import { FaCheck, FaCaretDown, FaTrashAlt, FaCircleNotch } from "react-icons/fa";
import config from "../../aws-exports";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { setEsDownFlagDoc, getTemplatesByTeamIDElasticsearch, getTemplatesByTeamID, getReportsRedis, createOnRedis, getWOTemplates, getEstimateTemplates, getCompletedScopesByClientID, createOwnerContact, createDocument, createDocWithTemplate, getDefaultCategories, getAssignDefaultTemplates, getMxDocID, addDocOrders, getSearchedContactsByClientID} from '../../graphql/queries';
import PropertyOwnerContact from './../CommonComponents/PropertyOwnerContact';
import CreateDocumentReport from './CreateDocumentReport';
import Cancel from '../../assets/cancel.svg';
import Tick from "../../assets/tickbox.svg";
import Tick1 from "../../assets/checkmark 2.svg";
import Loader from '../../assets/loading.gif';
import CheckMark from '../../assets/checkmark1.svg';
import CheckBox from '../../assets/checkmark3.svg';
import { navigate } from "gatsby";
import ToastBox from '../Toast/Toast';
import ScopeTypes from "../../constants/ScopeTypes";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
Amplify.configure(config);
const animatedComponents = makeAnimated();

const CaretDownIcon = () => {
    return <FaCaretDown color={"#000"} />;
  };
  
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

export default function CreateDocument(props) {
    const {consoleLog} = require('../commonFunctions.js');
    const [showContactLabel, setShowContactLabel] = React.useState(false);
    const [contacts, setContacts] = React.useState([]);
    const [totalContacts, setTotalContacts] = React.useState(0);
    const [contactsLimit, setContactsLimit] = React.useState(parseInt(process.env.GATSBY_DEFAULT_LIST_SIZE ?? 10));
    const [showAddNewContactLabel, setShowAddNewContactLabel] = React.useState(true);

    const [ownerName, setOwnerName] = React.useState('');
    const [ownerEmail, setOwnerEmail] = React.useState('');
    const [ownerPhone, setOwnerPhone] = React.useState('');

    const [searchContact, setSearchContact] = React.useState('');

    const [scopeTypes, setScopeTypes] = React.useState([]);
    const [searchResults, setSearchResults] = useState([]);
    
    const [first_name, setFirstName] = React.useState('');
    const [last_name, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const [title, setTitle] = useState("Estimate");
    const [state, setState] = useState({ on: false });
    const [state5, setState5] = useState({ on: false });
    
    const [searchReport, setSearchReport] = React.useState("");
    const [selectedReports, setSelectedReports] = React.useState([]);
    const [selectedReportTypes, setSelectedReportTypes] = React.useState([]);
    const [aDTemplates, setaDTemplates] = useState([]);

    const [repSearchLoader, setRepSearchLoader] = useState(false);

    const [showdiv0, setShowdiv0] = React.useState(false);

    const roofing_generic_template = [
        {
            id: 0,
            index: "prodocs-templates",
            template_id: 0,
            name: "Roofing generic starter template 1",
            description: "Roofing generic template"
        },
        {
            id: 1,
            index: "prodocs-templates",
            template_id: 1,
            name: "Roofing generic starter template 2",
            description: "Roofing generic template"
        },
    ];

    const onClick0 = () => {
        let reportsDiv = showdiv0;
        setShowdiv0(!showdiv0);

        if(reportsDiv && selectedReports.length){
            setShowdiv4(true);
            setSelectedTemplates('my');
            setDisplayTemplates(myTemplates);

            selectDefaultTemplate( selectedReports[0].scope_type_id );
        }
    }

    const selectDefaultTemplate = scope_type_id => {
        
        consoleLog("selectDefaultTemplate scope_type_id", scope_type_id);
        consoleLog("selectDefaultTemplate aDTemplates", aDTemplates);
        consoleLog("selectDefaultTemplate selectedDocType", selectedDocType);
        let doc_type_id = selectedDocType == 'estimate' ? 1 : 2;

        let d_template = aDTemplates.find(elem => (
            doc_type_id == elem.doc_type_id && scope_type_id == elem.scope_type_id
        ));
        
        consoleLog("selectDefaultTemplate d_template", d_template);
        consoleLog("selectDefaultTemplate myTemplates", myTemplates);
        
        if(!d_template) return;

        let selected_template = myTemplates.find(elem => (
            d_template.template_id == elem.template_id
        ))
        
        if(!selected_template) return;

        consoleLog("selectDefaultTemplate selected_template", selected_template);
        selectTemplate(selected_template.template_id, selected_template.name);
    }

    const [showdiv1, setShowdiv1] = React.useState(false)
    const onClick1 = () => setShowdiv1(!showdiv1)
    const [showdiv2, setShowdiv2] = React.useState(false)
    const onClick2 = () => setShowdiv2(!showdiv2)
    const [showdiv3, setShowdiv3] = React.useState(false)
    const onClick3 = () => setShowdiv3(!showdiv3)
    const [showdiv4, setShowdiv4] = React.useState(false)
    const onClick4 = () => setShowdiv4(!showdiv4)
    const data = [
        {
          value: 'estimate',
          label: "Estimate",
          id:'1'
        },
        {
          value: 'workorder',
          label: "Work Order",
          id:'2'
        },
    ];

    async function getDefaultReportTypes(){
        var rep_types = [];

        rep_types = ScopeTypes.map(scope=>(
            {
                label: scope.title,
                value: scope.id
            }
        ));
        
        consoleLog("rep_types: ", rep_types)
        setScopeTypes(rep_types);
    }

    async function loadMoreReportsFunc() {
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;
        consoleLog('loadMoreReportsFunc noOfRecs>>>', noOfRecs);
        consoleLog('loadMoreReportsFunc loadMoreReports>>>', loadMoreReports);
        consoleLog('loadMoreReportsFunc reportsCount>>>', reportsCount);
        let count = +loadMoreReports + +noOfRecs;
        consoleLog('loadMoreReportsFunc count>>>', count);
        setLoadMoreReports(count);

        let order_id = props.url_params?.order_id;
        if(!order_id){
            order_id = 0;
        }

        if(count < reportsCount){
            setLoadMoreReportsSpinner(true);
            let reports = [];
            let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
            const order_reports = await API.graphql({
                query: getCompletedScopesByClientID,
                variables: {
                    client_id: client_id,
                    order_id: order_id,
                    order_ids: '',
                    search: searchReport,
                    filter_ids: reportFilterIds,
                    limit: count
                }
            });
            reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));
    
            consoleLog("loadMoreReportsFunc reports:", reports);
            setSearchResults(reports.data);
            setReportsCount(reports.count);
            setLoadMoreReportsSpinner(false);
        }
    }

    async function fetchDocOrderReports() { //get reports
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;
        let order_id = props.url_params?.order_id;
        if(!order_id){
            order_id = 0;
        }
        consoleLog("fetchDocOrderReports noOfRecs:", noOfRecs);
        consoleLog("fetchDocOrderReports order_id:", order_id);

        // const rd_reports = await API.graphql({
        //     query: getReportsRedis,
        //     variables: {
        //         id: client_id
        //     }
        // });
        // let rd_reportx = JSON.parse(rd_reports.data.getReportsRedis);
        // consoleLog("fetchDocOrderReports rd_reports: ", rd_reportx);
        
        let reports = [];

        // if(rd_reportx.body == null || rd_reportx.body=='[]'){
            const order_reports = await API.graphql({
                query: getCompletedScopesByClientID,
                variables: {
                    client_id: client_id,
                    order_id: order_id,
                    order_ids: '',
                    search: searchReport,
                    filter_ids: reportFilterIds,
                    limit: noOfRecs
                }
            });
            // consoleLog('fetchDocOrderReports>>>',order_reports.data.getCompletedScopesByClientID);
            reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));
    
            consoleLog("fetchDocOrderReports reports:", reports);
            setSearchResults(reports.data);
            setReportsCount(reports.count);
            // const rd_create_reports = await API.graphql({
            //     query: createOnRedis,
            //     variables: {
            //         index: 'prodocs_reports',
            //         id: client_id,
            //         data: order_reports.data.getCompletedScopesByClientID
            //     }
            // });
            // consoleLog("fetchDocOrderReports: ", rd_create_reports);
        // }else{
        //     reports = JSON.parse(rd_reportx.body);
        //     consoleLog("fetchDocOrderReports else:", reports);
        //     setSearchResults(reports);
        // }
        if(order_id){
            let report = reports.data.find(report=>report.order_id == order_id);
            consoleLog("selected_report", report);
            if(report){
                setSelectedReports([report]);
                setTemplateName(report.name);
                setDocLocation(report.name);

                setShowdiv4(true);
                selectDefaultTemplate(report.scope_type_id);
                setSelectedTemplates('my');
            }
        }
    }

    const typeData = [
        {
          label: 'My Templates',
          value: "my"
        },
        {
          label: 'Starter Templates',
          value: "starter"
        }
    ];
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    let docType = props.url_params.doc_type_id == 2 ? 'workorder' : 'estimate';
    const [selectedDocType, setSelectedDocType] = useState(docType); //this
    const [selectedTemplates, setSelectedTemplates] = useState('starter');
    const [inputTemplateSearch, setInputTemplateSearch] = useState('');
    const [myTemplates, setMyTemplates] = useState([]);
    const [stTemplates, setStTemplates] = useState([]);
    const [displayTemplates, setDisplayTemplates] = useState([]);
    const [reportdD, setReportDd] = useState([]);
    const [reportFilterIds, setReportFilterIds] = useState('');
    const [loadMoreReports, setLoadMoreReports] = useState(process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10);
    const [loadMoreReportsSpinner, setLoadMoreReportsSpinner] = useState(false);
    const [loadingMoreContacts, setLoadingMoreContacts] = useState(false);
    const [reportsCount, setReportsCount] = useState(0);

    const [selectedTemplateId, setSelectedTemplateId] = useState(0); //this
    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [templateName, setTemplateName] = useState(''); //this
    const [docLocation, setDocLocation] = useState(''); //this
    const [estimateNumber, setEstimateNumber] = useState(''); //this
    const [claimNumber, setClaimNumber] = useState(''); //this
    const [ownerContactId, setOwnerContactId] = useState(0);
    const [show, setShow] = useState(false);

    async function handleChangeReportType (e) {
        setReportDd(e);
        setRepSearchLoader(true);
        consoleLog("handleChangeReportType selectedReportTypes>", e);
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;
        let sid  = e.map(type=>type.value) ?? [];
        consoleLog('handleChangeReportType sid>',sid);
        let filterIds = sid.join();
        consoleLog('handleChangeReportType filterIds>',filterIds);
        setSelectedReportTypes(sid);
        setReportFilterIds(filterIds);
        let reports = [];
        let order_id = props.url_params?.order_id;
        if(!order_id){
            order_id = 0;
        }
        const order_reports = await API.graphql({
            query: getCompletedScopesByClientID,
            variables: {
                client_id: client_id,
                order_id: order_id,
                order_ids: '',
                search: searchReport,
                filter_ids: filterIds,
                limit: noOfRecs,
            }
        });
        reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));

        consoleLog("handleChangeReportType reports:", reports);
        setSearchResults(reports.data);
        setReportsCount(reports.count);
        setLoadMoreReports(noOfRecs);
        setRepSearchLoader(false);
    }

    async function handleReportSearch (e) {
        consoleLog("handleReportSearch searchReport: ", e.target.value);
        setSearchReport(e.target.value);
        setRepSearchLoader(true);

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let noOfRecs = process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10;
        let reports = [];
        let order_id = props.url_params?.order_id;
        if(!order_id){
            order_id = 0;
        }
        const order_reports = await API.graphql({
            query: getCompletedScopesByClientID,
            variables: {
                client_id: client_id,
                order_id: order_id,
                order_ids: '',
                search: e.target.value,
                filter_ids: reportFilterIds,
                limit: noOfRecs
            }
        });
        reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));

        consoleLog("handleReportSearch reports:", reports);
        setSearchResults(reports.data);
        setReportsCount(reports.count);
        setLoadMoreReports(noOfRecs);
        setRepSearchLoader(false);
    }

    const handleDocTypeChange = e => {
        consoleLog('handleDocTypeChange',e.value);
        consoleLog('handleDocTypeChange',estimateNumber);
        setSelectedTemplateId(0);
        setSelectedDocType(e.value);
        setInputTemplateSearch('');
        fetchTemplates(e.value);

        let d = new Date();
        let year = d.getFullYear();
        if(e.value == 'workorder'){
            let num = estimateNumber.split("-");
            consoleLog('handleDocTypeChange',num);
            setEstimateNumber('WO'+year+'-'+num[1]);
        }else{
            let num = estimateNumber.split("-");
            consoleLog('handleDocTypeChange',num);
            setEstimateNumber('E'+year+'-'+num[1]);
        }
    }

    const handleTemplateName = e => {
        consoleLog('handleTemplateName',e.target.value);
        setTemplateName(e.target.value);
    }

    const handleEstimateNumber = e => {
        consoleLog('handleEstimateNumber',e.target.value);
        setEstimateNumber(e.target.value);
    }

    const handleClaimNumber = e => {
        consoleLog('handleClaimNumber',e.target.value);
        setClaimNumber(e.target.value);
    }

    const handleTemplateChange = e =>{
        consoleLog('handleTemplateChange', e.value);
        setSelectedTemplates(e.value);
        // setInputTemplateSearch('');
        handleTemplateSearchx(inputTemplateSearch, e.value);
        // if(e.value == 'starter'){
        //     setDisplayTemplates(stTemplates);
        // }
        // else{
        //     setDisplayTemplates(myTemplates);
        //     setForcedPage(pageOffset/itemsPerPage);
        // }
    }

    async function handleTemplateSearchx(e, type){
        let search = e;
        consoleLog('handleTemplateSearch search:', search);
        consoleLog('handleTemplateSearch type:',type);

        // setInputTemplateSearch(search);
        if(type == 'starter'){
            let s_res = stTemplates.filter((x) => x?.name.toLowerCase().includes(search.toLocaleLowerCase()));
            consoleLog('handleTemplateSearch s_res',s_res);
            setDisplayTemplates(s_res);
        }
        else{
            // let s_res = myTemplates.filter((x) => x?.name.toLowerCase().includes(search.toLocaleLowerCase()));
            // setDisplayTemplates(s_res);
            let selectedValue=1;
            if(selectedDocType=='workorder'){
                selectedValue=2;
            }
            let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
            const team_templates = await API.graphql({
                query: getTemplatesByTeamIDElasticsearch,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: 0,
                    search: search
                }
            });
            let my_templates = [];
            let my_templates_rec = 0;

            my_templates = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).data;
            my_templates_rec = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).count;

            consoleLog("handleTemplateSearch my_templates>",my_templates);
            consoleLog("handleTemplateSearch my_templates_re>",my_templates_rec);
            
            let m_temp = [];

            if(my_templates.length < 1){
                const team_templatex = await API.graphql({
                    query: getTemplatesByTeamID,
                    variables: {
                        team_id: team_id,
                        doc_type_id: selectedValue,
                        limit: itemsPerPage,
                        offset: 0,
                        search: search
                    }
                });
                my_templates = JSON.parse(team_templatex.data.getTemplatesByTeamID).data;
                my_templates_rec = JSON.parse(team_templatex.data.getTemplatesByTeamID).count.count;
                setMyTemplates(my_templates);
                setTempCount(my_templates_rec);
                m_temp=my_templates;
                consoleLog('handleTemplateSearch get from DB...',m_temp);
            }else{
                m_temp = my_templates.map(function(element){
                    return element._source;
                });
                setMyTemplates(m_temp);
                setTempCount(my_templates_rec);
            }
            setDisplayTemplates(m_temp)
            setForcedPage(0);
        }
    }
    
    async function handleTemplateSearch(e){
        let search = e.target.value;
        consoleLog('handleTemplateSearch', search);
        setInputTemplateSearch(search);
        if(selectedTemplates == 'starter'){
            let s_res = stTemplates.filter((x) => x?.name.toLowerCase().includes(search.toLocaleLowerCase()));
            setDisplayTemplates(s_res);
        }
        else{
            // let s_res = myTemplates.filter((x) => x?.name.toLowerCase().includes(search.toLocaleLowerCase()));
            // setDisplayTemplates(s_res);
            let selectedValue=1;
            if(selectedDocType=='workorder'){
                selectedValue=2;
            }
            let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
            const team_templates = await API.graphql({
                query: getTemplatesByTeamIDElasticsearch,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: 0,
                    search: search
                }
            });
            let my_templates = [];
            let my_templates_rec = 0;

            my_templates = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).data;
            my_templates_rec = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).count;

            consoleLog("handleTemplateSearch my_templates>",my_templates);
            consoleLog("handleTemplateSearch my_templates_re>",my_templates_rec);

            let m_temp = [];

            if(my_templates.length < 1){
                const team_templatex = await API.graphql({
                    query: getTemplatesByTeamID,
                    variables: {
                        team_id: team_id,
                        doc_type_id: selectedValue,
                        limit: itemsPerPage,
                        offset: 0,
                        search: search
                    }
                });
                my_templates = JSON.parse(team_templatex.data.getTemplatesByTeamID).data;
                my_templates_rec = JSON.parse(team_templatex.data.getTemplatesByTeamID).count.count;
                setMyTemplates(my_templates);
                setTempCount(my_templates_rec);
                m_temp=my_templates;
                consoleLog('handleTemplateSearch get from DB...',m_temp);
            }else{
                m_temp = my_templates.map(function(element){
                    return element._source;
                });
                consoleLog('handleTemplateSearch get from ES...',m_temp);
                setMyTemplates(m_temp);
                setTempCount(my_templates_rec);
            }
            setDisplayTemplates(m_temp)
            setForcedPage(0);
        }
    }

    const [itemsPerPage, setItemsPerPage] = React.useState(process.env.GATSBY_DEFAULT_LIST_SIZE ? process.env.GATSBY_DEFAULT_LIST_SIZE : 10);
    const [pageOffset, setPageOffset] = useState(0);
    const [forcedPage, setForcedPage] = useState(null);
    const [tempCount, setTempCount] = useState(0);
    
    async function handlePageChange(data){
        setForcedPage(data.selected);
        let offset = itemsPerPage*data.selected;
        consoleLog('handlePageChange', offset);
        consoleLog('handlePageChange', data);
        consoleLog('handlePageChange>> Page Number', data.selected);
        consoleLog('handlePageChange>> Items Per Page', props.itemsPerPage);
        consoleLog('handlePageChange>> Offset', offset);
        consoleLog('handlePageChange>> selectedDocType', selectedDocType);
        setPageOffset(offset);
        consoleLog('handlePageChange>> pageOffset', pageOffset);
        let selectedValue=1;
        if(selectedDocType=='workorder'){
            selectedValue=2;
        }
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        const team_templates = await API.graphql({
            query: getTemplatesByTeamIDElasticsearch,
            variables: {
                team_id: team_id,
                doc_type_id: selectedValue,
                limit: itemsPerPage,
                offset: offset,
                search: inputTemplateSearch
            }
        });
        let my_templates = [];
        let my_templates_rec = 0;

        my_templates = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).data;
        my_templates_rec = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).count;

        consoleLog("handlePageChange my_templates>",my_templates);
        consoleLog("handlePageChange my_templates_re>",my_templates_rec);

        let m_temp = [];

        if(my_templates.length < 1){
            const team_templatex = await API.graphql({
                query: getTemplatesByTeamID,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: offset,
                    search: inputTemplateSearch
                }
            });
            my_templates = JSON.parse(team_templatex.data.getTemplatesByTeamID).data;
            my_templates_rec = JSON.parse(team_templatex.data.getTemplatesByTeamID).count.count;
            setMyTemplates(my_templates);
            setTempCount(my_templates_rec);
            m_temp=my_templates;
            consoleLog('handleTemplateSearch get from DB...',m_temp);
        }else{
            m_temp = my_templates.map(function(element){
                return element._source;
            });
            setMyTemplates(m_temp);
            setTempCount(my_templates_rec);
        }
        setDisplayTemplates(m_temp)
    }

    async function fetchTemplates(docType){
        let selectedValue=1;
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_role = JSON.parse(secureLocalStorage.getItem('client_data')).team_role;
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;
        let client_email = JSON.parse(secureLocalStorage.getItem('client_data')).email;

        consoleLog("fetchTemplates client_id: ", client_id);
        consoleLog("fetchTemplates team_role: ", team_role);
        consoleLog("fetchTemplates team_id: ", team_id);
        consoleLog("fetchTemplates leader_id: ", leader_id);
        consoleLog("fetchTemplates client_email: ", client_email);

        consoleLog("fetchTemplates itemsPerPage: ", itemsPerPage);
        consoleLog("fetchTemplates pageOffset: ", pageOffset);
        if ((team_role == '' || team_role == null) && client_id == leader_id) {
            team_role = 'Admin';
        }
        consoleLog("fetchTemplates team_role: ", team_role);

        let wrk_templates = [];
        let wrk_templates_rec = 0;

        let est_templates = [];
        let est_templates_rec = 0;

        if(docType == 'workorder'){
            selectedValue = 2;
            consoleLog("fetchTemplates selectedValue: ", selectedValue);
            
            const team_workorder_templates = await API.graphql({
                query: getTemplatesByTeamIDElasticsearch,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: pageOffset,
                    search: ''
                }
            });

            consoleLog('fetchTemplates team_workorder_templates>',team_workorder_templates);

            wrk_templates = JSON.parse(team_workorder_templates.data.getTemplatesByTeamIDElasticsearch).data;
            wrk_templates_rec = JSON.parse(team_workorder_templates.data.getTemplatesByTeamIDElasticsearch).count;

            consoleLog("fetchTemplates wrk_templates>",wrk_templates);
            consoleLog("fetchTemplates wrk_templates_rec>",wrk_templates_rec);

            let m_temp = [];
            
            if(wrk_templates_rec.length < 1){
                const team_workorder_templatex = await API.graphql({
                    query: getTemplatesByTeamID,
                    variables: {
                        team_id: team_id,
                        doc_type_id: selectedValue,
                        limit: itemsPerPage,
                        offset: pageOffset,
                        search: ''
                    }
                });
                wrk_templates = JSON.parse(team_workorder_templatex.data.getTemplatesByTeamID).data;
                wrk_templates_rec = JSON.parse(team_workorder_templatex.data.getTemplatesByTeamID).count.count;
                setMyTemplates(wrk_templates);
                setTempCount(wrk_templates_rec);
                m_temp=wrk_templates;
                consoleLog('fetchTemplates get from DB...',m_temp);
            }else{
                m_temp = wrk_templates.map(function(element){
                    return element._source;
                });
                consoleLog('fetchTemplates get from ES...',m_temp);
                setMyTemplates(m_temp);
                setTempCount(wrk_templates_rec);
            }

            const starter_temp = await API.graphql({
                query: getWOTemplates,
                variables: {
                    user_id: process.env.GATSBY_STARTER_TEMPLATES_USER_ID,
                }
            });
            var s_temp = JSON.parse(starter_temp.data.getWOTemplates);
            s_temp = roofing_generic_template;
            setStTemplates(s_temp);
            consoleLog('fetchTemplates WO st>', s_temp);
            if(selectedTemplates == 'starter'){
                setDisplayTemplates(s_temp);
            }
            else{
                setDisplayTemplates(m_temp);
            }
        }else{
            const team_estimate_templates = await API.graphql({
                query: getTemplatesByTeamIDElasticsearch,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: pageOffset,
                    search: ''
                }
            });

            consoleLog('fetchTemplates>',team_estimate_templates);

            est_templates = JSON.parse(team_estimate_templates.data.getTemplatesByTeamIDElasticsearch).data;
            est_templates_rec = JSON.parse(team_estimate_templates.data.getTemplatesByTeamIDElasticsearch).count;
    
            consoleLog("fetchTemplates estimate_templates>",est_templates);
            consoleLog("fetchTemplates est_templates_rec>",est_templates_rec);
            
            let m_temp = [];
            
            if(est_templates.length < 1){
                const team_estimate_templatex = await API.graphql({
                    query: getTemplatesByTeamID,
                    variables: {
                        team_id: team_id,
                        doc_type_id: selectedValue,
                        limit: itemsPerPage,
                        offset: pageOffset,
                        search: ''
                    }
                });
                est_templates = JSON.parse(team_estimate_templatex.data.getTemplatesByTeamID).data;
                est_templates_rec = JSON.parse(team_estimate_templatex.data.getTemplatesByTeamID).count.count;
                setMyTemplates(est_templates);
                setTempCount(est_templates_rec);
                m_temp=est_templates;
                consoleLog('fetchTemplates get from DB...',m_temp);
            }else{
                m_temp = est_templates.map(function(element){
                    return element._source;
                });
                consoleLog('fetchTemplates get from ES...',m_temp);
                setMyTemplates(m_temp);
                setTempCount(est_templates_rec);
            }

            const starter_temp = await API.graphql({
                query: getEstimateTemplates,
                variables: {
                    user_id: process.env.GATSBY_STARTER_TEMPLATES_USER_ID,
                }
            });
            
            var s_temp = JSON.parse(starter_temp.data.getEstimateTemplates);
            s_temp = roofing_generic_template;
            setStTemplates(s_temp);
            consoleLog('fetchTemplates E st>', s_temp);
            if(selectedTemplates == 'starter'){
                setDisplayTemplates(s_temp);
            }
            else{
                setDisplayTemplates(m_temp);
            }
        }
    }

    function renderContactSection(){
        showContactLabel ? setShowContactLabel(false) : setShowContactLabel(true);
        (contacts.length > 0) ? setShowAddNewContactLabel(true) : setShowAddNewContactLabel(false);
    }

    async function loadMoreContacts(){
        setLoadingMoreContacts(true);
        let newLimit = contactsLimit + parseInt(process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10);
        setContactsLimit(newLimit);
        await getSearchedContacts('', newLimit);
        setLoadingMoreContacts(false);
    }

    function onPropertyOwnerChange(owner_contact_id, owner_detail){
        setOwnerContactId(owner_contact_id);

        owner_detail = JSON.parse(owner_detail);
        
        setOwnerName(owner_detail.name);
        setOwnerEmail(owner_detail.email);
        setOwnerPhone(owner_detail.phone);
    }

    function onSearchFromContact(e){
        let value = e.target.value;
        consoleLog('onSearchFromContact value', value);
        setSearchContact(value);
        getSearchedContacts(value);
    }

    async function getSearchedContacts(value = '', limit = contactsLimit){

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const results = await API.graphql({
            query: getSearchedContactsByClientID,
            variables: {
                client_id: client_id,
                type: "property_owner",
                search: value,
                limit: limit,
                selected_contact: ownerContactId
            }
        })
        let data = JSON.parse(results.data.getSearchedContactsByClientID);
        let searched_contacts = data.body?.contacts;
        let total_contacts = data.body?.total_contacts?.[0]?.count;

        consoleLog("onSearchFromContact results", results);
        consoleLog("onSearchFromContact data", data);
        consoleLog("onSearchFromContact searched_contacts", searched_contacts);
        consoleLog("onSearchFromContact total_contacts", total_contacts);

        if(searched_contacts){
            setContacts(searched_contacts);
            setContactByID(ownerContactId, searched_contacts);
        }
        if(total_contacts){
            setTotalContacts(total_contacts);
        }
    }

    const first_name_ref = useRef(0);
    const last_name_ref = useRef(0);
    const email_ref = useRef(0);
    const phone_ref = useRef(0);

    async function createOwnerPropertyContact(){
        if(!first_name){
            consoleLog('createOwnerPropertyContact first_name', first_name);
            first_name_ref.current.setCustomValidity("Please enter First Name.");
            first_name_ref.current.reportValidity();
            return false;
        }
        if(!last_name){
            consoleLog('createOwnerPropertyContact last_name', last_name);
            last_name_ref.current.setCustomValidity('Please enter Last Name.');
            last_name_ref.current.reportValidity();
            return false;
        }
        if(!email){
            consoleLog('createOwnerPropertyContact email', email);
            email_ref.current.setCustomValidity('Please enter Email.');
            email_ref.current.reportValidity();
            return false;
        }
        let reg = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        consoleLog('createOwnerPropertyContact reg',reg);
        if(!reg){
            email_ref.current.setCustomValidity('Please enter a valid Email.');
            email_ref.current.reportValidity();
            return false;
        }
        if(!phone){
            consoleLog('createOwnerPropertyContact phone', phone);
            phone_ref.current.setCustomValidity('Please enter Phone Number.');
            phone_ref.current.reportValidity();
            return false;
        }
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const create_owner_contact = await API.graphql({
            query: createOwnerContact,
            variables: {
                customer_id: client_id,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone
            }
        })
        consoleLog("createOwnerPropertyContact create_owner_contact: ", create_owner_contact);
        let new_contact_id = JSON.parse(create_owner_contact.data.createOwnerContact).insertId;
        consoleLog("createOwnerPropertyContact new_contact_id: ", new_contact_id);
        let contacts_copy = contacts;
        let new_contact_obj = {
            "address_id": 0,
            "name": null,
            "company": null,
            "address": null,
            "address_2": null,
            "city": null,
            "postcode": null,
            "country_id": null,
            "zone_id": null,
            "locked": null,
            "status": null,
            "code": null,
            "contact_id": new_contact_id,
            "customer_id": client_id,
            "type": "property_owner",
            "first_name": first_name,
            "last_name": last_name,
            "email": email,
            "phone": phone,
            "template_id": 0,
            "used_default": 1
        };
        consoleLog("createOwnerPropertyContact contacts_copy: ", contacts_copy);
        contacts_copy.unshift(new_contact_obj);
        consoleLog("createOwnerPropertyContact contacts_copy: ", contacts_copy);
        setContacts(contacts_copy);
        setShowAddNewContactLabel(true);
    }

    function onDeleteContact(contactId){
        getSearchedContacts(); 
        
        if(contactId === ownerContactId) {
            setOwnerContactId(null);
        }
    }

    const getDateTime = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
  
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
  
        let date = [year, month, day].join('-');

        let hour = d.getHours();
        let minutes = d.getMinutes();

        if (hour.length < 2)
            hour = '0' + hour;
        if (minutes.length < 2)
            minutes = '0' + minutes;

        let time = [hour, minutes].join(':');
  
        return date+' '+time+':00'; 
    }

    function selectTemplate (id, name){
        setSelectedTemplateId(id);
        setSelectedTemplateName(name);
    }

    async function createDocumentFunc(){
        consoleLog('createDocumentFunc selectedDocType>', selectedDocType);
        consoleLog('createDocumentFunc templateName>', templateName);
        consoleLog('createDocumentFunc estimateNumber>', estimateNumber);
        consoleLog('createDocumentFunc claimNumber>', claimNumber);
        consoleLog('createDocumentFunc selectedTemplateId>', selectedTemplateId);
        consoleLog('createDocumentFunc ownerContactId?', ownerContactId);

        if(!templateName){
            setShow(true)
            return false;
        }
        if(!estimateNumber){
            setShow(true)
            return false;
        }
        
        setLoading(true);

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
        let client_email = JSON.parse(secureLocalStorage.getItem("client_data")).email;

        var doc_type;

        if(selectedDocType == 'estimate'){
            doc_type = 1;
        }else{
            doc_type = 2;
        }

        var date_time = getDateTime();

        const create_doc = await API.graphql({
            query: createDocument,
            variables: {
                user_id: client_id,
                team_id: team_id,
                ref_id: estimateNumber,
                doc_type_id: doc_type,
                template_id: selectedTemplateId,
                name: templateName,
                claim_number: claimNumber,
                contact_id: 0,
                owner_contact_id: ownerContactId,
                date_created: date_time,
                date_updated: date_time,
                location: docLocation
            }
        });
        consoleLog('createDocumentFunc create_doc>>>',create_doc.data.createDocument);
        let created_doc= create_doc.data.createDocument;
        let created_doc_id = JSON.parse(created_doc).insertId;
        consoleLog('created_doc_id',created_doc_id);

        let poi = {};
        poi.owner_contact_id = ownerContactId;
        poi.name = ownerName;
        poi.email = ownerEmail;
        poi.phone = ownerPhone;
        consoleLog('createDocumentFunc poi>>>',poi);

        let doc_data = {
            user_id: client_id,
            team_id: team_id,
            ref_id: estimateNumber,
            doc_type_id: doc_type,
            name: templateName,
            claim_number: claimNumber,
            contact_id: 0,
            owner_contact_id: ownerContactId,
            date_created: date_time,
            date_updated: date_time,
            email: client_email,
            property_owner_info: JSON.stringify(poi),
            location: docLocation
        };

        consoleLog("createDocumentFunc doc_data>", doc_data);

        let es_status_one = 1;
        let es_status_two = 1;
        
        const create_docx = await API.graphql({ //create document lambda function
            query: createDocWithTemplate,
            variables: {
                temp_id: selectedTemplateId,
                doc_id: created_doc_id,
                doc_data: JSON.stringify(doc_data)
            }
        });
        consoleLog('createDocumentFunc create_docx>>>',create_docx);
        let templateData = create_docx.data.createDocWithTemplate;
        let templateDataArray = JSON.parse(templateData);
        consoleLog('createDocumentFunc templateDataArray>>>',templateDataArray);
        es_status_one = templateDataArray.es_status;
        consoleLog('handle_es_down es_status_one>', es_status_one);

        let scopeIds = [];
        for(const report of selectedReports) {
            scopeIds.push(report.scope_id);
        }

        consoleLog('createDocumentFunc scopeIds>>>',scopeIds);

        if(scopeIds.length > 0) {
            const create_docxx = await API.graphql({
                query: addDocOrders,
                variables: {
                    doc_id: created_doc_id,
                    doc_type_id : doc_type, 
                    scope_ids: scopeIds.join(','),
                    data: JSON.stringify(templateDataArray.data),
                    token: secureLocalStorage.getItem("user_token"),
                    action: 'add'
                }
            });
            consoleLog('createDocumentFunc create_docxx>',create_docxx);
            let statusCode = JSON.parse(create_docxx.data.addDocOrders).statusCode;
            es_status_two = JSON.parse(create_docxx.data.addDocOrders).esStatus;
            consoleLog('createDocumentFunc create_docxx>',statusCode);
            consoleLog('handle_es_down es_status_two>',es_status_two);
            if(statusCode==500){
                consoleLog('createDocumentFunc create_docxx>',statusCode);
                secureLocalStorage.setItem('report_error', 'The selected report is invalid and can not be added to this document.');
            }
            if(!es_status_two){
                let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
                es_down_docs.push({doc_id : created_doc_id, es_down_flag : '2'});
                consoleLog('handle_es_down es_down_docs>>>', es_down_docs);
                let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                consoleLog('handle_es_down temp_docs_temp>>>', temp_docs_temp);

                temp_docs_temp.body.docs = es_down_docs;
                consoleLog('handle_es_down temp_docs_temp final>>>', temp_docs_temp);
                secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));

                const set_flag_off = API.graphql({
                    query: setEsDownFlagDoc,
                    variables: {
                        doc_id: created_doc_id,
                        flag: '1'
                    }
                });
                consoleLog('handle_es_down set_flag_off>>>', set_flag_off);
            }
        }else{
            if(!es_status_one){
                let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
                es_down_docs.push({doc_id : created_doc_id, es_down_flag : '2'});
                consoleLog('handle_es_down es_down_docs>>>', es_down_docs);
                let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                consoleLog('handle_es_down temp_docs_temp>>>', temp_docs_temp);

                temp_docs_temp.body.docs = es_down_docs;
                consoleLog('handle_es_down temp_docs_temp final>>>', temp_docs_temp);
                secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));

                const set_flag_off = API.graphql({
                    query: setEsDownFlagDoc,
                    variables: {
                        doc_id: created_doc_id,
                        flag: '1'
                    }
                });
                consoleLog('handle_es_down set_flag_off>>>', set_flag_off);
            }
        }

        navigate('/template?template_id=0&&doc_type_id='+doc_type+'&&doc_id='+created_doc_id);
        // setLoading(false);
        // setModalShow(true);
    }

    function removeSelectedReport(scope_id){
        consoleLog("removeSelectedReport scope_id", scope_id);

        setSelectedReports((oldSelectedReports)=>{
            return oldSelectedReports.filter(report=>(
                report.scope_id != scope_id
            ))
        });
    }

    async function getADTemplates(){
        consoleLog('getADTemplates', '()');
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const d_temps = await API.graphql({
            query: getAssignDefaultTemplates,
            variables:{
                user_id:client_id
            }
        });

        consoleLog('getADTemplates', JSON.parse(d_temps.data.getAssignDefaultTemplates));
        setaDTemplates(JSON.parse(d_temps.data.getAssignDefaultTemplates));
    }

    async function generateEstimateNo(){
        let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
        const max_doc = await API.graphql({
            query: getMxDocID,
            variables:{
                id:team_id
            }
        });
        let max_doc_id = JSON.parse(max_doc.data.getMxDocID).max_doc_id;
        consoleLog('generateEstimateNo', max_doc_id);
        max_doc_id = max_doc_id;
        consoleLog('generateEstimateNo', max_doc_id);

        let d = new Date();
        let year = d.getFullYear();
        consoleLog('generateEstimateNo', year); //E2022-68751
        let initial = 'WO';
        if(selectedDocType == 'estimate'){
            initial = 'E';
        }
        consoleLog('generateEstimateNo', initial+year+'-'+max_doc_id);
        setEstimateNumber(initial+year+'-'+max_doc_id);
    }
    
    function setContactByID(contactId, allContacts = contacts){
        consoleLog("setContactByID allContacts", allContacts);
        consoleLog("setContactByID contactId", contactId);
        let selectedContact = allContacts.find(contact=>contact.contact_id==contactId);
        if(selectedContact){
            consoleLog("setContactByID selectedContact", selectedContact);
            setOwnerName(selectedContact.first_name + ' ' + selectedContact.last_name);
            setOwnerEmail(selectedContact.email);
            setOwnerPhone(selectedContact.phone);
        }
    }

    async function createDocumentOneClick() {
        setLoading(true);
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
        let client_email = JSON.parse(secureLocalStorage.getItem("client_data")).email;
        let doc_type = parseInt(props.url_params?.doc_type_id);
        let date_time = getDateTime();
        let order_id = props.url_params?.order_id;

        /////generate estimate number
        const max_doc = await API.graphql({
            query: getMxDocID,
            variables:{
                id:team_id
            }
        });
        let max_doc_id = JSON.parse(max_doc.data.getMxDocID).max_doc_id;
        max_doc_id = max_doc_id;

        let d = new Date();
        let year = d.getFullYear();
        let initial = 'WO';
        if(selectedDocType == 'estimate'){
            initial = 'E';
        }
        let est_num = initial+year+'-'+max_doc_id;
        consoleLog('createDocumentOneClick est_num: ', est_num);
        /////get template name
        let reports = [];
        const order_reports = await API.graphql({
            query: getCompletedScopesByClientID,
            variables: {
                client_id: client_id,
                order_id: order_id,
                order_ids: '',
                search: searchReport,
                filter_ids: reportFilterIds,
                limit: 5
            }
        });
        reports = JSON.parse(JSON.parse(order_reports.data.getCompletedScopesByClientID));
        let report = reports.data.find(report=>report.order_id == order_id);
        let temp_name = report.name;
        consoleLog('createDocumentOneClick temp_name: ', temp_name);
        /////create document
        const create_doc = await API.graphql({
            query: createDocument,
            variables: {
                user_id: client_id,
                team_id: team_id,
                ref_id: est_num,
                doc_type_id: doc_type,
                template_id: selectedTemplateId,
                name: temp_name,
                claim_number: '',
                contact_id: 0,
                owner_contact_id: 0,
                date_created: date_time,
                date_updated: date_time,
                location: temp_name
            }
        });
        consoleLog('createDocumentOneClick create_doc: ', create_doc.data.createDocument);
        let created_doc= create_doc.data.createDocument;
        let created_doc_id = JSON.parse(created_doc).insertId;
        consoleLog('createDocumentOneClick created_doc_id',created_doc_id);

        let poi = {};
        poi.owner_contact_id = ownerContactId;
        poi.name = ownerName;
        poi.email = ownerEmail;
        poi.phone = ownerPhone;

        let doc_data = {
            user_id: client_id,
            team_id: team_id,
            ref_id: est_num,
            doc_type_id: doc_type,
            name: temp_name,
            claim_number: '',
            contact_id: 0,
            owner_contact_id: 0,
            date_created: date_time,
            date_updated: date_time,
            email: client_email,
            property_owner_info: JSON.stringify(poi),
            location: temp_name
        };
        consoleLog('createDocumentOneClick doc_data: ',doc_data);

        let es_status_one = 1;
        let es_status_two = 1;

        const create_docx = await API.graphql({ //create document lambda function
            query: createDocWithTemplate,
            variables: {
                temp_id: 0,
                doc_id: created_doc_id,
                doc_data: JSON.stringify(doc_data)
            }
        });
        consoleLog('createDocumentOneClick create_docx>>>',create_docx);
        let templateData = create_docx.data.createDocWithTemplate;
        let templateDataArray = JSON.parse(templateData);
        consoleLog('createDocumentOneClick templateDataArray>>>',templateDataArray);
        es_status_one = templateDataArray.es_status;
        consoleLog('createDocumentOneClick es_status_one>', es_status_one);

        let scopeIds = [report.scope_id];
        // for(const report of selectedReports) {
        //     scopeIds.push(report.scope_id);
        // }
        consoleLog('createDocumentOneClick scopeIds>>>',scopeIds);

        if(scopeIds.length > 0) {
            const create_docxx = await API.graphql({
                query: addDocOrders,
                variables: {
                    doc_id: created_doc_id,
                    doc_type_id : doc_type, 
                    scope_ids: scopeIds.join(','),
                    data: JSON.stringify(templateDataArray.data),
                    token: secureLocalStorage.getItem("user_token"),
                    action: 'add'
                }
            });
            consoleLog('createDocumentOneClick create_docxx>',create_docxx);
            let statusCode = JSON.parse(create_docxx.data.addDocOrders).statusCode;
            es_status_two = JSON.parse(create_docxx.data.addDocOrders).esStatus;
            consoleLog('createDocumentOneClick create_docxx>',statusCode);
            consoleLog('createDocumentOneClick es_status_two>',es_status_two);
            if(statusCode==500){
                consoleLog('createDocumentOneClick create_docxx>',statusCode);
                secureLocalStorage.setItem('report_error', 'The selected report is invalid and can not be added to this document.');
            }
            if(!es_status_two){
                let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
                es_down_docs.push({doc_id : created_doc_id, es_down_flag : '2'});
                consoleLog('createDocumentOneClick es_down_docs>>>', es_down_docs);
                let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                consoleLog('createDocumentOneClick temp_docs_temp>>>', temp_docs_temp);

                temp_docs_temp.body.docs = es_down_docs;
                consoleLog('createDocumentOneClick temp_docs_temp final>>>', temp_docs_temp);
                secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));

                const set_flag_off = API.graphql({
                    query: setEsDownFlagDoc,
                    variables: {
                        doc_id: created_doc_id,
                        flag: '1'
                    }
                });
                consoleLog('createDocumentOneClick set_flag_off>>>', set_flag_off);
            }
        }else{
            if(!es_status_one){
                let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
                es_down_docs.push({doc_id : created_doc_id, es_down_flag : '2'});
                consoleLog('createDocumentOneClick es_down_docs>>>', es_down_docs);
                let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                consoleLog('createDocumentOneClick temp_docs_temp>>>', temp_docs_temp);

                temp_docs_temp.body.docs = es_down_docs;
                consoleLog('createDocumentOneClick temp_docs_temp final>>>', temp_docs_temp);
                secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));

                const set_flag_off = API.graphql({
                    query: setEsDownFlagDoc,
                    variables: {
                        doc_id: created_doc_id,
                        flag: '1'
                    }
                });
                consoleLog('createDocumentOneClick set_flag_off>>>', set_flag_off);
            }
        }

        navigate('/template?template_id=0&&doc_type_id='+doc_type+'&&doc_id='+created_doc_id);
    }

    useEffect(() => {
        if(props.url_params?.order_id && props.url_params?.doc_type_id){
            let one_click = JSON.parse(secureLocalStorage.getItem('client_data')).one_click;
            if(one_click == 1){
                console.log('createDocumentOneClick', one_click);
                createDocumentOneClick();
            }
        }
        fetchTemplates(selectedDocType);
        getADTemplates();
        getSearchedContacts();
        getDefaultReportTypes();
        fetchDocOrderReports();
        generateEstimateNo();
    }, [selectedTemplates]);



    return (

        <div>
            <CreateTemplateNavbar />
            {modalShow &&
                <div className="modal-class">
                    <Modal
                        show={modalShow}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        style={{opacity:'1'}}
                        className='mt-0'
                    >
                        <Modal.Header>

                            <Modal.Title id="contained-modal-title-vcenter">
                                <div className="text-center my-3">
                                    <img src={CheckBox} style={{width:'60px', height:'auto'}} alt=""/>
                                </div>
                                <p className="modal-title-x mb-0 mt-2">Your Document Has Been Created.</p>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Footer>
                            <Button className="modal-btn-x" onClick={()=>setModalShow(false)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
            { loading ?
                <LoadingContainer>
                    <img className="loader" src={Loader}/>
                </LoadingContainer>

            :
            
                <Wrapper>
                    <WrapperContent>
                        <div className="box" id="pageTitle">
                            <p className="title m-0">
                                Create New Document
                            </p>
                        </div>
                        <div className="box">
                            <p className="cTitle">
                                Document Type
                            </p>
                            <div className="inputEle">
                                <Select
                                    placeholder="Select Template Type"
                                    value={data.find(obj => obj.value === selectedDocType)}
                                    options={data} 
                                    onChange={handleDocTypeChange}
                                    className="select-class-input"
                                    components={{ DropdownIndicator }}
                                    styles={{
                                        placeholder: (base) => ({
                                          ...base,
                                          fontSize: "1em",
                                          fontFamily: "Roboto",
                                          color: "#B4BEBF",
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                
                                          boxShadow: "none",
                                          border: "1px solid #B4BEBF",
                                          overflowX: "hidden",
                                          border: state.isFocused && "1px solid #B4BEBF",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          border: state.isFocused && "1px solid #B4BEBF",
                                          border: "1px solid #B4BEBF",
                                          overflowX: "hidden",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                
                                          overflowX: "hidden",
                                          backgroundColor: state.isFocused && "#F7F9FA",
                                          color: state.isFocused && "#212E33",
                                          borderBottom: "1px solid #B4BEBF",
                                          cursor: 'pointer'
                                        }),
                                    }}
                                    isSearchable={false}
                                />


                            </div>
                        </div>
                        <div className="box">
                            <p className="cTitle">
                                Document Name{templateName=='' && <span style={{color:"#dc3545"}}> *</span>}
                            </p>
                            <div className="inputEle w-100">
                            <input
                                    placeholder="Enter Document Name"
                                    className="w-100 ip"
                                    onChange={handleTemplateName}
                                    value={templateName}
                                />

                            </div>
                        </div>
                        {
                            !templateName  ?
                                <ToastBox
                                setShow={setShow}
                                show={show}
                                Text={"Please enter Document Name."}
                                Type={'Error'}
                                />
                            : null
                        }
                        {
                             templateName  && !estimateNumber  ?
                                <ToastBox
                                setShow={setShow}
                                show={show}
                                Text={"Please enter Estimate Number."}
                                Type={'Error'}
                                />
                            : null
                        }
                        {
                             templateName  && estimateNumber && !selectedTemplateId ?
                                <ToastBox
                                setShow={setShow}
                                show={show}
                                Text={"Please select Template."}
                                Type={'Error'}
                                />
                            : null
                        }
                        
                        <div className="box">
                            <p className="cTitle">
                            {selectedDocType == 'estimate' ? 'Estimate Number' : 'Order Number'} {estimateNumber=='' && <span style={{color:"#dc3545"}}> *</span>}
                            </p>
                            <div className="inputEle w-100">
                            <input
                                    placeholder="Reference #"
                                    className="w-100 ip"
                                    onChange={handleEstimateNumber}
                                    value={estimateNumber}
                                />
                            </div>
                        </div>
                        
                        <div className="box">
                            <p className="cTitle ">
                            Claim/Reference Number
                            </p>
                            <div className="inputEle w-100">
                            <input
                                    placeholder="Enter Number"
                                    className="w-100 ip"
                                    onChange={handleClaimNumber}
                                    value={claimNumber}
                                />
                            </div>
                        </div>

                        <div className="box2 box">
                            <p className="cTitle2">Reports</p>
                            <p className="cDesc mt-2">Associating a Scope Report will populate your document with real-time data.</p>
                            
                            <div className="selected_reports">
                                { selectedReports.map((selectedReport)=>(
                                    <div
                                    className="selected_report"
                                    key={"remove-"+selectedReport.scope_id}
                                    onClick={()=>removeSelectedReport(selectedReport.scope_id)}
                                    >
                                        <span><FaTrashAlt/></span>
                                        <span>{selectedReport.scope_type_id == 1 && 'RoofScope'}{selectedReport.scope_type_id == 4 && 'GutterScope'}{selectedReport.scope_type_id == 11 && 'PaintScope'}{selectedReport.scope_type_id == 6 && 'SidingScope'}{selectedReport.scope_type_id == 7 && 'InsulationScope'}{selectedReport.scope_type_id == 10 && 'ConcreteScope'}{selectedReport.scope_type_id == 12 && 'RoofScopeX'}{selectedReport.scope_type_id == 17 && 'RoofScope+'}  {selectedReport.name}</span>
                                    </div>
                                )) }
                            </div>

                            <IconButton style={{outline:'none',border:'none'}} className="p-1 link-section" onClick={onClick0} >
                                {
                                    showdiv0 ? (
                                        <div className="hoverx">
                                            <img style={{width:"26px" }} src={Cancel} />
                                            <p style={{marginTop:'3px', display: 'inline'}} className="text-linking-a">Close</p>
                                        </div>
                                    ) : (
                                        <div className="hoverx">
                                            <img style={{width:"26px" }} src={AddPlus} />
                                            <p style={{marginTop:'3px', display: 'inline'}} className="text-linking-a">Add Reports</p>
                                        </div>
                                    )
                                }   
                            </IconButton>
                            { showdiv0 ?  
                            <div className="w-100">
                                <div className="reports">
                                    <div className="sp-mb-cus">
                                        <p className="text m-0">Search Reports:</p>               
                                        <div className="f_input">
                                            <input type="text" placeholder="Search by address / name" value={searchReport} onChange={handleReportSearch} className="ip w-100"/>
                                            <a className="search-btn">
                                                {repSearchLoader ?
                                                    <FaCircleNotch size={22} className="awesome_spinner"/> 
                                                :
                                                    <img src={searchImg} />
                                                }
                                            </a>
                                        </div>
                                    </div>
                                    <div className="sp-mb-cus mb-0">
                                        <p className="text m-0">Choose Report Type(s)</p>    
                                        <Select
                                            placeholder="Select all that apply"
                                            // value={data.find(obj => obj.value === selectedValue)}
                                            value={reportdD}
                                            isMulti
                                            options={scopeTypes}
                                            onChange={handleChangeReportType}
                                            className="w-100"
                                            components={{ DropdownIndicator }}
                                            styles={{
                                                placeholder: (base) => ({
                                                  ...base,
                                                  fontSize: "1em",
                                                  fontFamily: "Roboto",
                                                  color: "#B4BEBF",
                                                }),
                                                control: (provided, state) => ({
                                                  ...provided,
                        
                                                  boxShadow: "none",
                                                  border: "1px solid #B4BEBF",
                                                  overflowX: "hidden",
                                                  border: state.isFocused && "1px solid #B4BEBF",
                                                }),
                                                menu: (provided, state) => ({
                                                  ...provided,
                                                  border: state.isFocused && "1px solid #B4BEBF",
                                                  border: "1px solid #B4BEBF",
                                                  overflowX: "hidden",
                                                }),
                                                option: (provided, state) => ({
                                                  ...provided,
                        
                                                  overflowX: "hidden",
                                                  backgroundColor: state.isFocused && "#F7F9FA",
                                                  color: state.isFocused && "#212E33",
                                                  borderBottom: "1px solid #B4BEBF",
                                                  cursor: 'pointer',
                                                }),
                                            }}
                                            isSearchable={false}
                                        />
                                    
                                    </div>
                                </div>
                                <div className="reportsData">
                                {/* <Table striped responsive>
                                    <thead></thead>
                                    <tbody>
                                        <tr >
                                        <td className="custom-font">logo</td>
                                        <td className="custom-font">1 Lan Kwai Fong, Central, Hong Kong</td>
                                        <td className="custom-font"> Dec 15, 2020</td>
                                        <td className="custom-font">0.0 SQ , 1 Bldg </td>
                                        <td className="custom-font">View PDF</td>
                                        </tr>
                                    </tbody>
                                    </Table> */}
                                    { 
                                        searchResults.length ? (
                                            <div className="search_results">
                                            {        
                                            // searchResults.filter(searchResult=>(
                                            //     selectedReportTypes.length ? selectedReportTypes.includes(searchResult.scope_type_id) : true
                                            // )).filter(searchResult=>(
                                            //     searchResult.name.toLowerCase().includes(searchReport.toLowerCase())
                                            // )).map(searchResult => (
                                                searchResults.map(searchResult => (
                                                    <CreateDocumentReport
                                                        key={searchResult.scope_id}
                                                        searchResult={searchResult}
                                                        selectedReports={selectedReports}
                                                        isSelected={
                                                            selectedReports.length ? selectedReports.some(report=>(
                                                                searchResult.scope_id == report.scope_id
                                                            )) : false
                                                        }
                                                        setSelectedReports={setSelectedReports}
                                                        templateName={templateName}
                                                        setTemplateName={setTemplateName}
                                                        setDocLocation={setDocLocation}
                                                    />
                                                ))
                                            }
                                            </div>
                                        ) : (
                                            <div className="alert-box">
                                                <p className="text-ab mb-0">There are no reports matching your search.</p>
                                            </div>
                                        )
                                    }
                                </div>
                                { loadMoreReports < reportsCount &&
                                    <button className="load-more-btn" onClick={loadMoreReportsFunc}>{ loadMoreReportsSpinner ? <><span>Loading&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><FaCircleNotch size={20} className="awesome_spinner"/></> : 'Load More'}</button>
                                }
                            </div>
                            : null }
                        </div>
                        
                        {/* Property Owner Contact Section */}
                        <div className="detail_heading_row pt-5 pb-5 border-top box">
                            <p className="cTitle2">Property Owner Info</p>
                            <p className="cDesc">Add a property owner’s information as a reference (this will appear on your document).</p>
                            { ownerContactId != 0 && 
                                <div className="owner">
                                    <div className="mt-5">
                                        <span className="detail_heading_label">Name:</span>
                                        <span className="detail_title_text ml-3">{ ownerName }</span>
                                    </div>

                                    <div>
                                        <span className="detail_heading_label">Email:</span>
                                        <span className="detail_title_text ml-3">{ ownerEmail }</span>
                                    </div>

                                    <div>
                                        <span className="detail_heading_label">Phone:</span>
                                        <span className="detail_title_text ml-3" >{ ownerPhone }</span>
                                    </div>
                                </div>
                            }
                            <div className="project-info-fields on-always amp-toggle-content is-dormant mt-5">
                                <IconButton style={{outline:'none',border:'none'}} className="p-1 hoverx" onClick={renderContactSection}>
                                    <span>
                                        <img src={showContactLabel ? Cancel : AddPlus} style={{width: '26px'}} alt=""/>
                                    </span>
                                    <a style={{marginTop:'2px'}} onClick={renderContactSection} className="text-linking-a">{showContactLabel ? 'Close' : 'Add Property Owner'}</a>
                                </IconButton>
                                { showContactLabel &&
                                    <>
                                        <div className="reports-company">
                                            <p className="text m-0">Search for an existing contact by company, name, email or phone</p>
                                            
                                            <div className="f_input">
                                                <input type="text" placeholder="Enter Any Contact info to search" className="ip w-100" value={searchContact} onChange={onSearchFromContact}/>
                                                <a className="search-btn mt-0"><img src={searchImg} /></a>
                                            </div>
                                        </div>
                                        <Row className="contact_info_box ml-0 mr-0 mt-3 pb-0">
                                            { showAddNewContactLabel
                                                ? <h5 className="cursor-pointer border-bottom pb-3 mb-0 w-100" onClick={() => setShowAddNewContactLabel(false)}><span className="color-red ml-3 mr-3 contact-spacing"><img src={AddPlus} style={{width:"26px" }} alt=""/></span>Add New Contact</h5>
                                                : <h5 className="cursor-pointer border-bottom pb-3 mb-0 w-100" onClick={() => setShowAddNewContactLabel(true)}><span className="color-red ml-3 mr-3"><img src={Cancel} style={{width:"26px" }} alt=""/></span>Cancel</h5>
                                            }
                                            <div className="w-100 contact_info_add_cancel">
                                                { !showAddNewContactLabel
                                                ?
                                                    <Row className="m-0">
                                                        <Col className="property-col" xs={12} sm={12} md={6}>
                                                            <input type="text" placeholder="First Name" className="setting-input" value={first_name} ref={first_name_ref} onInput={(e)=> setFirstName(e.target.value)}/>
                                                            <input type="text" placeholder="Last Name" className="setting-input" value={last_name} ref={last_name_ref} onInput={(e)=> setLastName(e.target.value)}/>
                                                            <input type="text" placeholder="Email" className="setting-input" value={email} ref={email_ref} onInput={(e)=> setEmail(e.target.value)}/>
                                                            <input type="text" placeholder="Phone" className="setting-input" value={phone} ref={phone_ref} onInput={(e)=> setPhone(e.target.value)}/>
                                                            <Button className="btn_save_contact mt-4 mb-3" onClick={createOwnerPropertyContact}>SAVE CONTACT </Button>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6}></Col>
                                                    </Row>
                                                :
                                                    contacts.map(filtered_contact => (
                                                        <PropertyOwnerContact owner_contact_id={ownerContactId} setOwnerName={setOwnerName} setOwnerEmail={setOwnerEmail} setOwnerPhone={setOwnerPhone} contact_info={filtered_contact} onContactDelete={onDeleteContact} onChangePropertyOwner={onPropertyOwnerChange}/>
                                                    ))
                                                }
                                            </div>
                                            {
                                                showAddNewContactLabel && (totalContacts > contactsLimit) && <div className="border-top w-100">
                                                    <button className="load-more-btn" onClick={loadMoreContacts}>
                                                        {loadingMoreContacts
                                                        ? <>Loading <span style={{marginLeft: '10px'}}><FaCircleNotch size={20} className="awesome_spinner"/></span></>
                                                        : 'Load More'}
                                                    </button>
                                                </div>
                                            }
                                        </Row>
                                    </>
                                }
                            </div>
                        </div>  

                        <div className="box2 box custom-margin-bottom">
                            <p className="cTitle2">Select Template{selectedTemplateId=='' && <span style={{color:"#dc3545"}}> *</span>}</p>
                            <p className="cDesc m-0">Select a template to start a document. Or use the search tool to find a previously saved template...</p>
                            
                        <p className="currentTemp">Current Template: {selectedTemplateName != '' ? selectedTemplateName : 'None' }</p>
                            <IconButton style={{outline:'none',border:'none'}} className="p-1 hoverx" /*onClick={() => setState5((state5) => ({ on: !state5.on }))}*/ onClick={onClick4}>
                                    
                                    <span>
                                        {showdiv4 ?
                                            <ToggleIcon
                                                on={state5.on}
                                                onIcon={<img src={Cancel} style={{width:"26px" }} />}
                                                offIcon={<img src={Cancel} style={{width:"26px" }}/>}
                                            />
                                            :
                                            <ToggleIcon
                                                on={state5.on}
                                                onIcon={<img src={AddPlus} style={{width:"26px" }} />}
                                                offIcon={<img src={AddPlus} style={{width:"26px" }}/>}
                                            />
                                        }
                                    </span>
                                    <p style={{marginTop:'2px'}} onClick={onClick4} className="text-linking-a">{showdiv4 ? 'Close' : 'Change Template'}</p>
                            </IconButton>
                            { showdiv4 ?  
                            <div className="w-100">
                                <div className="reports-temp">
                                <div className="rt-mb-cus">
                                <p className="text report-type" style={{marginTop:'-11px'}}>Choose a Template</p>    
                                    <Select
                                        placeholder="Select all that apply"
                                        value={typeData.find(obj => obj.value === selectedTemplates)} 
                                        // isMulti
                                        options={typeData}
                                        onChange={handleTemplateChange}
                                    
                                        components={{ DropdownIndicator }}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                boxShadow: "none",
                                                border: "1px solid #B4BEBF !important",
                                            }),
                                            menu: (provided, state) => ({
                                                ...provided,
                                                border: "1px solid #B4BEBF",
                                                boxShadow: "0 2px 5px 0 rgba(0,0,0,0.3)",
                                                border: "1px solid #B4BEBF !important",
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                color: "#B4BEBF",
                                                fontStyle: "italic",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: "#ffff !important",
                                                color: state.isFocused && "#737F82",
                                                cursor: 'pointer'
                                            }),
                                        }}
                                        isSearchable={false}
                                    />
                                    
                                </div>
                                    <div className="f_input rt-mb-cus2">   
                                        <input type="text" placeholder="Search By Any Field" className="ip w-100" onChange={handleTemplateSearch} value={inputTemplateSearch}/>
                                        <a className="search-btn"><img src={searchImg} /></a>
                                    </div>
                                </div>
                                
                                <div className="item-list line-item-item-list">
                                    {displayTemplates.map((temp, i) => (    
                                        <div style={{
                                            cursor:"pointer",
                                            background:
                                            selectedTemplateId ==
                                            temp.template_id
                                                ? "#D3F8EE"
                                                : "#fff",
                                        }} 
                                        className="item item-value line-item-add-item search-result"
                                        onClick={()=>selectTemplate(temp.template_id, temp.name)}
                                        >
                                            <div className="checkbox py-2">
                                                
                                                {selectedTemplateId == temp.template_id ? (
                                                        <img src={Tick} className="TickIcon" alt="" />
                                                    ) : (
                                                        <img src={Tick1} className="TickIcon" alt="" />
                                                )}

                                                <div className="label-col col xs-10 left">
                                                    <div className="starter_template_detail">
                                                        <h5 className="my-0">
                                                            <strong>{temp.name}</strong>
                                                        </h5>
                                                        <p className="my-0">
                                                            {temp.description}
                                                        </p>
                                                    </div>
                                                </div>
                               
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {selectedTemplates == 'my' && tempCount > 0 &&
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        pageCount={tempCount/itemsPerPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={forcedPage}
                                    />
                                }
                            </div>
                            : null }
                        </div>

                    <div className="w-100 d-flex align-items-center justify-content-end c-btnn">
                            <button className="inputs-btn" onClick={createDocumentFunc}>Continue</button>
                    </div>


                    </WrapperContent>
                </Wrapper>
            }
            <Footer />
        </div>
    )
}


const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
    }
`

const Row = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
padding:10px;

.logo{
    img{
        width:100px;
        height:auto;
    }
}
`
const Wrapper = styled.div`
width:100%;
min-height:500px;
display:flex;
align-items:center;
justify-content:flex-start;
flex-direction:column;
background-color:#F7F9FA;

`

const WrapperContent = styled.div`
width:70%;

.starter_template_detail {
    text-align: left;
    padding-left: 20px;

    &:hover {
        color: #F7941C !important;
    }
}

.TickIcon {
    position: absolute;
    top: 30%;
    padding-left: 10px;
}

.search-result {
    cursor: pointer;
    border: 1px solid #b4bebf;
}

.line-item-item-list {
    background: white;
    margin-top: 20px;
    border-radius: 4px;
    overflow: hidden;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

@media(max-width: 1024px) {
    width: 100%;
    padding-inline: 50px;
}

@media(max-width: 767px) {
    width: 100%;
    padding-inline: 20px;
}

.reports-company{
    @media(max-width: 767px) {
        margin-top: 10px;
    }
    

    .f_input{

           display:flex;
           align-items:flex-start;

           @media(max-width: 767px) {
            margin-top: 10px;
        }

           .search-btn{
            margin-left:15px;
            margin-right:0px;
            height: 40px;
            width: 40px;
            border-radius: 3px;
            background-color: #D6E1E5;
            display:flex;
            align-items:center;
            justify-content:center;
            border-radius:4px;

            @media(max-width: 767px) {
                margin-right: 0;
            }
            
            
         }
       }
}


.c-btnn{
    margin-bottom:163px;

    @media(max-width: 1024px) {
        margin-bottom : 30px;
    }

    .inputs-btn{
            height: 44px;
            width: 169px;
            border-radius: 3px;
            background-color: #F7941C;
            color: #212E33;
            border:none;
            outline:none;
            font-family: Rajdhani;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.6px;
            line-height: 21px;
            text-align: center;
            display:flex;
            align-items:center;
            justify-content:center;
            &:hover {
                background-color: #000 !important ;
                color: #fff !important;
              }
        }
}
.custom-margin-bottom{
    margin-bottom:70px;

    @media(max-width: 1024px){
        margin-bottom: 30px;
    }
}

.css-45l6q1-menu {
    div:nth-child(1) {
        border-bottom: 1px solid rgba(0,0,0,0.4);
    }
  }

.reports-temp{
    display:flex;
    align-items:center;





}

.currentTemp{
    color: #E1892D;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin:35px 0 41px 0;
    font-weight:600;
}

.box2{
    min-height:200px !important;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
    border-top:1px solid #D6E1E5;
    border-bottom:1px solid #D6E1E5;
    padding:15px 0;

    @media(max-width: 768px){
        border-bottom: none;
    }
}

.cTitle2{
    color: #212E33;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -1.07px;
    line-height: 28px;
}
.new-contact{
    border-bottom:1px solid #D6E1E5;
}
.alert-box{
    height: 50px;
    width: 100%;
    border-radius: 6px;
    background-color: #D3F8EE;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    margin:30px 0;
    padding:10px;
}
.text-ab{
    color: #006349;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.hoverx:hover .text-linking-a{
    color: #F7941C !important;
}

.text-linking-a{
    color: #212E33 !important;
    font-family: Rajdhani;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 21px;
    margin-left:15px;

     &:hover {
        color: #F7941C !important;
     }
}
.MuiIconButton-root:hover {
    background-color: transparent !important;
    color: #F7941C;
}
#pageTitle{
        display:flex;
        align-items:center;
        justify-content:flex-start;
    }
.box{
    min-height:115px;

    @media(max-width: 767px) {
        min-height: auto;
        margin-block: 20px 0;
        padding-bottom: 0;
    }

    .text-light-owner{
        font-size:15px;
        color:#6a6e6f;
        font-weight:500;
        margin:15px 0;
    }
    .ip{
        width: 100%;
        height: 40px;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-wrap: wrap;
        color: #212e33;
        font-style: normal;
        justify-content: space-between;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
        padding: 2px 8px;
    }
    .title{
        color: #212E33;
        font-family: Roboto;
        font-size: 28px;
        font-weight: 300;
        letter-spacing: -1.24px;
        line-height: 33px;
    }
    .cTitle{
        color: #212E33;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
    }
    .cDesc{
        font-size:14px;
    }
    .select-class-input {
        margin-top:14px;
        width: 300px;

        @media(max-width:600px){
            width:100%;
        }
    }

    .css-5b9gaa-menu {
        div:last-child {
           border-bottom: none;
        }
      }
   .inputEle{
    .ip{
        width: 100%;
        height: 40px;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-wrap: wrap;
        color: #212e33;
        font-style: normal;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
        padding: 2px 8px;
    }
   }

   .reports{
       display:flex;
       align-items:center;
       justify-content:center;
       width:100%;
       margin:10px 0;

       @media(max-width:600px){
           flex-direction:column;
           margin-bottom:18px;
       }
        .sp-mb-cus{
            width:50%;
        @media(max-width:600px){
           margin-bottom:18px;
           width:100%;
         }
        }


       .f_input{
           display:flex;

           @media(max-width: 767px) {
            margin-top: 10px;
        }
           .search-btn{
            margin-left:15px;
            margin-right:15px;
            height: 40px;
            width: 40px;
            border-radius: 3px;
            background-color: #D6E1E5;
            display:flex;
            align-items:center;
            justify-content:center;
            border-radius:4px;

            @media(max-width: 767px) {
                margin-right: 0;
            }
         }
       }
   }

   .report-type {
    @media(max-width:767px){
        margin-top: 5px !important;
      }
   }

   .reports-temp{
       width:100%;
       margin:10px 0;
       @media(max-width:767px){
              display:flex;
              flex-direction:column;
              margin: 0;
            }
       .rt-mb-cus{
            width:35%;
            @media(max-width:600px){
                margin-bottom:18px;
                width:100%;
            }
        }
        .rt-mb-cus2{
            width:65%;
            @media(max-width:600px){
                width:100%;
            }
        }
       .f_input{
           display:flex;
           width:75%;
           align-items:center;
           padding-top:19px;
           margin-left:15px;

           @media(max-width: 767px) {
            margin-left: 0;
            margin-top: 10px;
            padding-top: 0;
        }
           .search-btn{
            margin-left:15px;
            margin-right:0px;
            height: 40px;
            width: 40px;
            border-radius: 3px;
            background-color: #D6E1E5;
            display:flex;
            align-items:center;
            justify-content:center;
            border-radius:4px;

            @media(max-width: 767px) {
                margin-right: 0;
            }
         }

         @media(max-width:600px){
                width:100%;
            }
       }
   }
   .reportsData{

       .custom-font{
           font-size:15px;
       }
   }

   .contactData{
       width:100%;
       border: 1px solid #B4BEBF;
       border-radius: 4px;
       background-color: #FFFFFF;
       min-height:20px;
       margin:30px 0 75px 0;

       @media(max-width: 1024px) {
        margin-bottom: 15px;
        padding-bottom: 0 !important;
       }

        .tempDetailsReports{
            border-bottom: 1px solid #B4BEBF;
            padding:15px 0;
            display:flex;
            align-items:center;
            .title{
                position:relative;
                display: inline;
                color: #212E33;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
                left:10px;
                top:2px;
            }
            .desc{
                position:relative;
                color: #212E33;
                font-family: Roboto;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                top: 5px;
            }
            .TickIcon {
                position: relative;
                display: inline;
              }
        }

        .tempDetailsReports:last-child {
            border-bottom: none !important;
        }

    .contactDetailData{
        min-height:200px;
      
        .f-row{
            width:100%;
            display:flex;
            align-items:flex-start ;
            flex-direction:column;

            padding:10px;
            border-bottom:1px solid #B4BEBF;

            .f-row-item{
                color: #212E33;
                font-family: Roboto;
                font-size: 14px;
                font-weight:bold;
                letter-spacing: 0;
                line-height: 18px;
            }
            .f-row-item-span{
                color: #212E33;
                font-family: Roboto;
                font-size: 14px;
                font-weight:normal;
                letter-spacing: 0;
                line-height: 18px;
            }
        }

    }
    .basic-inputs-contact{
        min-height: 374px;
        width: 100%;
        border: 1px solid #B4BEBF;
        border-radius: 4px;
        background-color: #EEF2F4;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        padding:30px;


        .title{
            color: #212E33;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: -1.07px;
            line-height: 28px;
            text-align: center;
            margin-bottom:30px;
        }

        .basic-inputs-btn{
            height: 44px;
            width: 169px;
            border-radius: 3px;
            background-color: #F7941C;
            color: #212E33;
            font-family: Rajdhani;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.6px;
            line-height: 21px;
            text-align: center;
            display:flex;
            align-items:center;
            justify-content:center;
        }

    }
    .basic-inputs{
        width:50%;
        padding:15px;    

        }
        .basic-inputs-btn{
            width:100%;
            border:none;
            outline:none;
            background-color:red;
            color:#fff;
            font-weight:500;
            text-transform:uppercase;
            font-size:17px;
            height:40px;
            margin-top:25px;

        }

   }
}


.setting-input{
    height:40px;
    width:100%;
    outline:none;
    padding:10px;
    border-radius:4px;
    border:1px solid #B4BEBF;
    color:#212E33;
    font-style: normal;
    font-size:14px;
    margin-top:8px;
}
.owner {
    color: #f09245;
}
.detail_heading_label{
    font-family: Roboto;
    font-size: 14px;
    font-weight:500;
    letter-spacing: 0;
    line-height: 16px;
}
.contact_info_box{
    border:1px solid #B4BEBF;
    border-radius: 3px;
    padding-inline: 0 !important;
    flex-direction: column;
}
.contact-spacing {
    @media(max-width: 767px) {
            margin-left: 2px !important;
    }
}
.border-bottom{
    border-bottom: 1px solid #bdbdbd;
}
.border-top{
    border-top: 1px solid #bdbdbd;
}
.cursor-pointer{
    cursor: pointer;
    &:hover  {
        color: #F7941C;
      }
}

.property-col {
    padding-inline: 0 !important;
}
.btn_save_contact{
    height: 50px;
    width: 50%;
    color: #212E33;
    font-family: Rajdhani;
    background-color: #F7941C !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 21px;
    text-align: center;
    outline:none;
    border:none;
    &:hover {
        background-color: #000 !important;
        color: #fff;
      }

    @media(max-width:767px){
        width:100%;
        margin-inline: 0 !important;
    }
}
.color-red {
    color: red !important;
}
.s_nav_item{
    color: #212E33;
    font-family: Rajdhani;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 21px;
}
    .selected_reports{
        margin-bottom: 10px;
        color: #E1892D;
    }
    .selected_report span svg{
        margin-right: 10px;
    }
    .selected_report{
        margin: 8px;
        cursor: pointer;
    }
    .selected_report:hover{
        color:#212E33;
    }
    .search_results{
        border: 1px solid #B4BEBF;
        border-radius: 5px;
        overflow-y: scroll;
        max-height: 400px;
        background: white;
        overflow-y: auto !important;
    }
    .load-more-btn{
        height: 44px;
        width: 169px;
        border-radius: 3px;
        background-color: #F7941C;
        color: #212E33;
        border: none;
        outline: none;
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 21px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px auto;
    }
    .load-more-btn:hover {
        color: white;
        background-color: black;
    }
    .contact_info_add_cancel{
        max-height: 400px;
        overflow-y: auto;
    }
`;
